import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ico',
  templateUrl: './ico.component.html',
  styleUrls: ['./ico.component.scss']
})
export class IcoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
