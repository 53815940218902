<div class="doctor">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/doctor"><img src="assets/img/black-logo.png" alt="image"></a>
                        </div>
    
                        <h5>We're Coming Soon..</h5>
                        <h1>We are launching very soon!</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisssit amet, consectetur.</p>
    
                        <div class="btn-box">
                            <button class="btn btn-primary notify-btn">Get Notified <i class="fas fa-bell"></i></button>
                            <button class="btn btn-light get-more-info-btn">Get More Info <i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>
    
                    <app-countdown></app-countdown>
                </div>
            </div>
        </div>
    
        <div class="shape1"><img src="assets/img/circle1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/circle2.png" alt="image"></div>
    </div>
</div>

<app-sidebar></app-sidebar>

<app-notify></app-notify>