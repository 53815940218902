<div class="plumbing">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/plumbing"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                        <h1>We are coming soon</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
        
                            <button type="submit">Notify Me</button>
                        </form>

                        <app-countdown></app-countdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>