<div class="travel-1">
    
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/travel-1"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                        
                        <app-countdown></app-countdown>

                        <h1><span>We are</span> coming soon</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida maecenas accumsan.</p>

                        <div class="btn-box">
                            <button class="btn btn-primary notify-btn">Get Notified</button>
                            <button class="btn btn-light get-more-info-btn">Get More Info</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="particles-js"></div>

        <footer class="footer-area">
            <div class="container">
                <ul>
                    <li><span>Stay Connect:</span></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>
    
</div>

<app-sidebar></app-sidebar>

<app-notify></app-notify>