<div id="timer">
    <div id="days">
        {{days}} <span>Days</span>
    </div>
    <div id="hours">
        {{hours}} <span>Hours</span>
    </div>
    <div id="minutes">
        {{minutes}} <span>Minutes</span>
    </div>
    <div id="seconds">
        {{seconds}} <span>Seconds</span>
    </div>
</div>