<div class="it">
  <div class="coming-soon-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="coming-soon-content">
            <div class="logo">
              <!-- <a routerLink="/it"><img src="assets/img/white-logo.png" alt="image"></a> -->
              <img _ngcontent-cjn-c5="" src="https://cdn.outerlimitstech.com/outerlimits/site-logo-20200329x5000.png"
                alt="OuterLimits Software Development Company">
            </div>
            <h1>A new site is coming</h1>
            <!-- <p>We currently do not have a launch date as we are busy helpling our clients</p> -->

            <!-- <app-countdown></app-countdown> -->

            <!-- <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
        
                            <button type="submit">Notify Me</button>
                        </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
