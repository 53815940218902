<div class="gym">
    <section class="coming-soon-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-8 col-md-12 p-0">
                    <div class="coming-soon-image">
                        <img src="assets/img/gym-bg.jpg" alt="image">

                        <div class="logo">
                            <a routerLink="/gym"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>

                        <app-countdown></app-countdown>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12 p-0">
                    <div class="coming-soon-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h5>We'll see you soon</h5>
                                <h1>We’re doing our best to launch ASAP</h1>

                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter Email Address" name="EMAIL" required autocomplete="off">

                                    <button type="submit">Notify Me</button>
                                </form>

                                <div class="social">
                                    <h3><span>Or Stay in Touch</span></h3>
                                    <ul>
                                        <li><a href="#" target="_blank" class="twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" class="youtube"><i class="fab fa-youtube"></i></a></li>
                                        <li><a href="#" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" class="linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" class="instagram"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>