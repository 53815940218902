<div class="creative-3">
    <section class="coming-soon-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="coming-soon-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="logo">
                                    <img src="assets/img/black-logo.png" alt="logo">
                                </div>

                                <app-countdown></app-countdown>
                                
                                <h1>Our website is Coming Soon, follow us for update now!</h1>

                                <form class="newsletter-form">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter Email Address" name="EMAIL" required autocomplete="off">
                                    </div>

                                    <button type="submit" class="btn btn-primary">Subscribe Now</button>
                                </form>

                                <div class="social">
                                    <ul>
                                        <li><a href="#" target="_blank" class="twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" class="youtube"><i class="fab fa-youtube"></i></a></li>
                                        <li><a href="#" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" class="linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" class="instagram"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="coming-soon-image">
                        <img src="assets/img/creative-bg2.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>