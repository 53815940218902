<div class="ico">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/ico"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            Contact Us
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="coming-soon-area">
        <div class="container-fluid p-0">
            <div class="row m-0 align-items-center">
                <div class="col-lg-5 col-md-12 p-0">
                    <div class="coming-soon-image wow fadeInLeft">
                        <img src="assets/img/ico-main-image.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-7 col-md-12 p-0">
                    <div class="coming-soon-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h5>We are Under Construction</h5>
                                <h1>Get Ready For Our Ico Launch</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <app-countdown></app-countdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape"><img src="assets/img/shape6.png" alt="image"></div>
    </section>
</div>

<app-sidebar></app-sidebar>