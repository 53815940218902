<div class="agency">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/agency"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>
    
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1>We are coming soon</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
        
                            <button type="submit">Notify Me</button>
                        </form>
                    </div>

                    <div class="countdown-timer">
                        <div class="inner-content">
                            <h3>Emilono - launching in</h3>

                            <app-countdown></app-countdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="footer-area">
            <div class="container">
                <ul>
                    <li><span>Stay Connected:</span></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>
</div>

<app-sidebar></app-sidebar>