<div class="real-estate">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/real-estate"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="coming-soon-content">
                                <div class="logo">
                                    <a routerLink="/real-estate"><img src="assets/img/white-logo.png" alt="image"></a>
                                </div>

                                <h2>We are launching soon please  <span>reload after days</span></h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Quis ipsum suspendisse.</p>

                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">

                                    <button type="submit">Notify Me</button>
                                </form>

                                <app-countdown></app-countdown>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="coming-soon-image">
                                <img src="assets/img/real-estate-main-image.png" class="wow fadeInUp" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1 wow fadeInLeft slow"><img src="assets/img/shape1.png" alt="image"></div>
        <div class="shape2 wow fadeInRight slow"><img src="assets/img/shape2.png" alt="image"></div>
    </div>
</div>

<app-sidebar></app-sidebar>