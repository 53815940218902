<div class="construction">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>
    
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <h5>We Are Coming</h5>
                        <h1>Coming soon...</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitlabore et dolore magna aliquaolor sit amet consectetur.</p>
    
                        <app-countdown></app-countdown>
    
                        <button class="notify-btn">Notify Me Update</button>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    
        <footer class="footer-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p>Copyright 2020 by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>. All rights reserved</p>
                    </div>
    
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><span>Stay Connect:</span></li>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>

<app-sidebar></app-sidebar>

<app-notify></app-notify>