<div class="restaurant-2">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/restaurant-2"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="coming-soon-area">
        <video loop muted autoplay poster="#" class="video-background">
            <source src="assets/video/restaurant-video.mp4" type="video/mp4">
        </video>
    
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="coming-soon-content">
                                <h5>Yes! We Are Coming</h5>
                                <h1>Something big is coming soon</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu sit amet consectetur adipisicing.</p>
    
                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
    
                                    <button type="submit">Notify Me</button>
                                </form>
                            </div>
                        </div>
    
                        <div class="col-lg-7 col-md-12">
                            <div class="count-down-timer">
                                <app-countdown></app-countdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-sidebar></app-sidebar>