<!-- Subscribe Modal -->
<div class="subscribe-modal">
    <div class="subscribe-modal-inner">
        <div class="subscribe-modal-content">
            <div class="newsletter-header">
                <div class="animation-icons">
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                </div>

                <img src="assets/img/bell-icon.png" alt="image">
            </div>

            <h2>Subscribe Newsletter</h2>
            <p>Being the first to know always feels great. Signing up to our newsletter gives you exclusive access to our Grand Opening!</p>

            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">

                <button type="submit">Subscribe Now</button>
            </form>

            <span class="close-btn subscribe-modal-close-btn"><i class="fas fa-times"></i></span>
        </div>
    </div>
</div>
<!-- End Subscribe Modal -->