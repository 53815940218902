<div class="event-1">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/event-1"><img src="assets/img/black-logo.png" alt="image"></a>
                        </div>
                    </div>
    
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="countdown-timer">
                                <div id="timer">
                                    <div id="days">
                                        {{days}} <span>Days to Launch</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-7 col-md-12">
                            <div class="coming-soon-content">
                                <div class="logo">
                                    <a routerLink="/event-1"><img src="assets/img/black-logo.png" alt="image"></a>
                                </div>
                                <h1>Something big update is coming to our <span>updates!</span></h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    
                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter Email Address" name="EMAIL" required autocomplete="off">
    
                                    <button type="submit">Notify Me</button>
                                </form>
    
                                <div class="social">
                                    <h3><span>Or Stay in Touch</span></h3>
                                    <ul>
                                        <li><a href="#" target="_blank" class="twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank" class="youtube"><i class="fab fa-youtube"></i></a></li>
                                        <li><a href="#" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank" class="linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank" class="instagram"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="shape1"><img src="assets/img/shape7.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/shape8.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/shape9.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/shape10.png" alt="image"></div>
        <div class="shape5"><img src="assets/img/shape11.png" alt="image"></div>
        <div class="shape6"><img src="assets/img/shape12.png" alt="image"></div>
        <div class="shape7"><img src="assets/img/shape13.png" alt="image"></div>
        <div class="shape8"><img src="assets/img/shape14.png" alt="image"></div>
        <div class="shape9"><img src="assets/img/shape15.png" alt="image"></div>
        <div class="shape10"><img src="assets/img/shape16.png" alt="image"></div>
        <div class="shape11"><img src="assets/img/shape17.png" alt="image"></div>
        <div class="shape12"><img src="assets/img/shape18.png" alt="image"></div>
        <div class="shape13"><img src="assets/img/shape19.png" alt="image"></div>
    </div>
</div>

<app-sidebar></app-sidebar>