<div class="seo">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/seo"><img src="assets/img/black-logo.png" alt="image"></a>
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="main-banner-content">
                                <app-countdown></app-countdown>

                                <h1>We are launching soon please reload after days</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>

                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
                
                                    <button type="submit">Notify Us</button>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/seo-img.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="footer-area">
            <div class="container">
                <ul>
                    <li><span>Stay Connected:</span></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>
</div>

<app-sidebar></app-sidebar>